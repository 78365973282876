import * as React from 'react';

import { useMediaQuery } from '@mui/material';
import { List, SimpleList, Datagrid, TextField, ReferenceField } from 'react-admin';

import { TextInput } from 'react-admin';


const postFilters = [
    <TextInput label="Search Outlet" source="restaurantName" alwaysOn />,
    <TextInput label="Outlet" source="restaurantName" defaultValue="Koramangala" />,
    <TextInput label="City" source="address.city" defaultValue="Bangalore" />,
];

export const OutletList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List  {...props} filters={postFilters}> 
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.resturantName}
                    secondaryText={record => `${record.address.city} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="view">
                  
                  {/*   <TextField source="id" /> */}
                    <ReferenceField label="#" source="id" reference="outlets">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField label="Outlet Id" source="resId" />
                    <TextField label="Outlet Name" source="restaurantName" />
                    <TextField label="City" source="address.city" />
                    <TextField label="Updated At" source="updatedAt" />
                </Datagrid>
            )}
        </List>
    );
};
export default OutletList