import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import { List, SimpleList, Datagrid, TextField, ReferenceField } from 'react-admin';

import { TextInput } from 'react-admin';


const postFilters = [
    // <TextInput label="Search Outlet" source="outlet" alwaysOn />,
    <TextInput label="Outlet Name" source="outlet"  />,
    <TextInput label="Guest Name" source="guestName" />,
    <TextInput label="orderType" source="orderType" defaultValue="Dine-in" />,
    <TextInput label="Phone" source="phoneNumber"  />,
    <TextInput label="Feedback Rating" source="rating"  />,
];

export const FeedbacksList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List  {...props} filters={postFilters}> 
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.outlet}
                    secondaryText={record => `${record.phoneNumber} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="edit">
                  {/*   <TextField source="id" /> */}
                    <ReferenceField label="#" source="id" reference="feedbacks">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField label="Phone #" source="phoneNumber" />
                    <TextField label="Guest Name" source="guestName" />
                    <TextField label="Outlet Name" source="outlet" />
    
                    <TextField label="Rating" source="rating" />
                    <TextField label="Order Type" source="orderType" />
                    <TextField label="Date" source="registerDate" />
                    <TextField label="Time" source="registerTime" />
                    
                    <TextField label="Comments" source="comments" />
                    <TextField label="Member Since" source="memberSince" />
                    <TextField label="Total Orders" source="orders" />
                    <TextField label="Feedbacks" source="feedbacks" />
                </Datagrid>
            )}
        </List>
    );
};
export default  FeedbacksList