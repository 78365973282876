import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import IconEvent from '@mui/icons-material/Event'
import { List, SimpleList, Datagrid, TextField, ReferenceField,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Button} from 'react-admin';

import { TextInput } from 'react-admin';


const postFilters = [
    <TextInput label="Search Outlet" source="outlet"   alwaysOn />,
    // <TextInput label="Delivery Count" source="deliveryCount" />,
    // <TextInput label="Take Away Count" source="takeAwayCount" />,
    // <TextInput label="Dine-In Count" source="dineInCount" />,
    // <TextInput label="Total" source="numberOfOrders" />,
];
const ListActions = () => (
     <TopToolbar>
        <FilterButton/>
        <ExportButton maxResults={10000} />
    </TopToolbar>
);
export const SampleSummaryList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List actions={<ListActions/>} {...props} filters={postFilters}> 
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.id}
                    secondaryText={record => `${record.outlet} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="edit">
                  {/*   <TextField source="id" /> */}
                    <ReferenceField label="#" source="id" reference="samplesummary">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField label="Outlet" source="outlet" />
                    <TextField label="Delivery Orders" source="deliveryCount" />
                    <TextField label="TakeAway" source="takeAwayCount" />
                    <TextField label="Dine-In" source="dineInCount" />
                    <TextField label="Total" source="totalOrders" />
                    <TextField label="Updated At" source="updatedAt" />
                </Datagrid>
            )}
        </List>
    );
};
export default SampleSummaryList ;