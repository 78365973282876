import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import IconEvent from '@mui/icons-material/Event'
import { List, SimpleList, Datagrid, TextField, ReferenceField,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    Button} from 'react-admin';

import { TextInput } from 'react-admin';


const postFilters = [
    <TextInput label="Search Outlet" source="restaurantName"   alwaysOn />,
    <TextInput label="Total Orders" source="numberOfOrders" />,
    <TextInput label="Outlet ID" source="resId" />,
];
const ListActions = () => (
     <TopToolbar>
        <FilterButton/>
        <ExportButton maxResults={10000} />
    </TopToolbar>
);
export const OrderSummaryList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List actions={<ListActions/>} {...props} filters={postFilters}> 
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.resId}
                    secondaryText={record => `${record.restaurantName} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="edit">
                  {/*   <TextField source="id" /> */}
                    <ReferenceField label="#" source="id" reference="orders">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField label="Outlet Id" source="resId" />
                    <TextField label="Outlet Name" source="restaurantName" />
                    <TextField label="Total Orders" source="numberOfOrders" />
                    <TextField label="Order Date" source="orderDate" />
                    <TextField label="Updated At" source="updatedAt" />
                </Datagrid>
            )}
        </List>
    );
};
export default OrderSummaryList ;