import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import { List, SimpleList, Datagrid, TextField, ReferenceField,
    TopToolbar,
    FilterButton,
    ExportButton,
} from 'react-admin';

import { TextInput } from 'react-admin';


const ListActions = () => (
    <TopToolbar>
    <FilterButton/>
   
   <ExportButton disabled={true}/>
</TopToolbar>
);

const postFilters = [
    <TextInput label="Search Outlet" source="outlet" alwaysOn />,
    <TextInput label="Search Outlet" source="outlet"/>,
    <TextInput label="Guest Name" source="guestName" />,
    <TextInput label="Phone #" source="phoneNumber" />,
    <TextInput label="orderType" source="orderType" defaultValue="Dine-in" />,
];

export const OrdersList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List actions={<ListActions/>}{...props} filters={postFilters}> 
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.outlet}
                    secondaryText={record => `${record.phoneNumber} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="edit">
                  {/*   <TextField source="id" /> */}
                    <ReferenceField label="#" source="id" reference="orders">
                        <TextField source="id" />
                    </ReferenceField>
                    <TextField label="Phone #" source="phoneNumber" />
                    <TextField label="Guest Name" source="guestName" />
                    <TextField label="Outlet Name" source="outlet" />
                    <TextField label="Order Type" source="orderType" />
                    <TextField label="orderTime" source="orderTime" />
                    <TextField label="orderId" source="orderId" />
                    <TextField label="memberSince" source="memberSince" />
                    <TextField label="Total Orders" source="orders" />
                    <TextField label="Feedbacks" source="feedbacks" />
                </Datagrid>
            )}
        </List>
    );
};
export default OrdersList