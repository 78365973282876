import { Admin, Resource, ListGuesser, memoryStore } from "react-admin";
import jsonServerProvider from "ra-data-json-server";

import authProvider from '../authProvider';
import Dashboard from '../Dashboard';
import NotFound from '../NotFound';
import dtSampler from './dtSampler';
import  { OutletList } from './outlets';
import {OrdersList} from './orders';
import {OrderSummaryList} from './ordersummary';

import { FeedbacksList} from './feedbacks';
import {FeedbackSummaryList} from './feedbacksummary';

import {SampleList} from './ordersamples';
import {SampleSummaryList} from './samplesummary';

import RestaurantIcon from '@mui/icons-material/Restaurant';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CallIcon from '@mui/icons-material/Call';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import GroupsIcon from '@mui/icons-material/Groups';
//const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
//const dataProvider = jsonServerProvider("http://localhost:32002");

const dataProvider = dtSampler;

const App = () => (
  <Admin 
    title="DT Sampler"
    dashboard={Dashboard}

    dataProvider={dataProvider} 
     store={memoryStore()}
     authProvider={authProvider}
     catchAll={NotFound}
    
    >
   
   {/* 
   <Resource name="outlets" list={ ListGuesser} />
    */}
    <Resource name="outlets" list={ OutletList} icon={RestaurantIcon} />
    <Resource name="ordersummary" list={ OrderSummaryList} icon={SummarizeIcon} />
    <Resource name="orders" list={ OrdersList} icon={BorderColorIcon} />
    <Resource name="samplesummary" list={ SampleSummaryList} icon={GroupsIcon} />
    <Resource name="ordersamples" list={ SampleList} icon={CallIcon} />
    <Resource name="feedbacksummary" list={ FeedbackSummaryList} icon={RecentActorsIcon} />
    <Resource name="feedbacks" list={ FeedbacksList} icon={FeedbackIcon} />
 
 
  </Admin>
);

export default App;