import * as React from "react";
import { Card, Grid,Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

import dayjs from 'dayjs';


export default () => (
    <Card sx={{p:2}}>
        {/* {JSON.stringify(dayjs().format("YYYY-MM-DD HH:mm:ss A"))} */}
        <Typography variant='h4'>Dashboard</Typography>
        <Grid container>
            <Grid item md={6}>
                <Title title="Welcome to Dindigul Thallappakatti Customer Expereince Monitoring Center" />
                <CardContent>Dindigul Thalappakatti Order Dashboard for {dayjs().add(-1, 'day').format("YYYY-MM-DD")}</CardContent>
                <CardContent>Outlets - Lists the Current Outlets.</CardContent>
                <CardContent>Order Summary - Lists the Order Summary for each Outlet...</CardContent>
                <CardContent>Orders - Consolidated list of All Orders.</CardContent>
                <CardContent>Samplesummary - Summary of Samples - Outletwise</CardContent>
                <CardContent>OrderSamples - Sample Orders for Outbound Calling</CardContent>
                <CardContent>Feedback Summaries -Summary of Feedback got in each Outlet</CardContent>
                <CardContent>Feedback - Detailed List of Feedback</CardContent>
            </Grid>
            <Grid item md={6}>
           {/*  <video width="320" height="240" controls autoplay muted>
            <source src={require("./assets/images/the-legend-tvc.mp4")} alt='dt' type="video/mp4"/>
            </video>
    */}           
      <img src={require("./assets/images/madiwala_branch05.jpeg")} alt='dt'width="480" height="320"/>
           
            </Grid>

        </Grid>


    </Card>

);